import React, { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/global-styles.css';

/**
 * COMPONENTS
 */
import Modal from "./components/Modal/Modal";
import Header from './components/Header/Header.js';
import Menu from './components/Menu.js';

/**
 * CONTAINERS
 */
import Login from "./containers/Auth/Login";
import SendResetPasswordMail from "./containers/Auth/SendResetPasswordMail";
import ResetPassword from "./containers/Auth/ResetPassword";
import Customers from "./containers/Customers/Customers";
import Departments from "./containers/Departments/Departments";
import CreateDepartment from './containers/Departments/CreateDepartment';
import CreateCustomer from "./containers/Customers/CreateCustomer";
import EditDepartment from './containers/Departments/EditDepartment';
import ShowDepartment from './containers/Departments/ShowDepartment';
import EditCustomer from "./containers/Customers/EditCustomer";
import Tasks from "./containers/Tasks/Tasks";
import ShowTask from "./containers/Tasks/ShowTask";
import EditTaskStatus from "./containers/Tasks/EditTaskStatus";
import CancelledTasks from "./containers/Tasks/CancelledTasks";
import Users from "./containers/Users/Users";
import ShowUser from "./containers/Users/ShowUser";
import CreateUser from "./containers/Users/CreateUser";
import EditUser from "./containers/Users/EditUser";
import Rates from './containers/Rates/Rates';
import GeneralRates from './containers/Rates/GeneralRates';
import Error404 from './containers/Errors/Error404';
import SourceLanguages from "./containers/SourceLanguages/SourceLanguages";
import Quote from "./containers/Quote/Quote";
import RegisteredQuote from "./containers/Quote/RegisteredQuote";
import ShowTaskQuote from "./containers/Tasks/ShowTaskQuote";
import Logs from "./containers/Logs/Logs";

import * as UserTypes from './store/reducers/auth/userTypes';
import CreateTaskVerifyFiles from "./containers/Tasks/CreateTask/CreateTaskVerifyFiles";
import CorrectPayment from "./containers/Tasks/CreateTask/CorrectPayment";
import IncorrectPayment from "./containers/Tasks/CreateTask/IncorrectPayment";
import GuestTask from "./containers/Guest/GuestTask";
import GuestCorrectPayment from "./containers/Guest/GuestCorrectPayment";
import ShowUserProfile from "./containers/Users/UserAccount";
import UserCompany from "./containers/Users/UserCompany"
import Glossaries from "./containers/Glossaries/Glossaries";
import {IntlProvider} from "react-intl";
import Footer from "./components/Footer";
import ErrorBoundary from "./hoc/ErrorBoundary/ErrorBoundary";

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedMenu = useSelector(state => state.menu.selectedMenu);
    const user = useSelector(state => state.auth.user);
    const userType = useSelector(state => state.auth.user ? state.auth.user.type : UserTypes.invitado);
    const locale = useSelector(state => state.locale.locale);

    useEffect(() => {
        dispatch({type: 'CHANGE_MENU', payload: {from: location.pathname, to: ""}});
        dispatch({type: "CLEAR_ASSIGN_DATA"});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const closeDropdown = () => {
        for (let dropdown of document.getElementsByClassName("dropdown"))
            dropdown.classList.remove("is-active");
    }

    const AuthRoutes = () => (
        <Switch>
            <Route path="/login" component={Login} exact/>
            <Route path="/login/reset" component={SendResetPasswordMail} exact/>
            <Route path="/login/reset/:token" component={ResetPassword} exact/>

            <Route path="/" exact render={() => !user && <Redirect to="/login"/>}/>
        </Switch>
    );

    const PublicRoutes = () => (
        <Switch>
            <Route path="/guest-task/ko/" component={IncorrectPayment} exact/>
            <Route path="/guest-task/ok/:id/" component={GuestCorrectPayment} exact/>
            <Route path="/guest-task/:id/" component={GuestTask} exact/>

            <Route path="/404" component={Error404} exact/>

            <Route path="/quote" component={Quote} exact/>
            <Route path="/quote/registered" component={RegisteredQuote} exact/>

            <Route path="*" render={() => <Redirect to="/login"/>}/>
        </Switch>
    );

    const IDISCAdminRoutes = () => (
        <Switch>
            <Route path="/" component={Tasks} exact/>
            <Route path="/tasks" component={Tasks} exact/>
            <Route path="/tasks/:id/" component={ShowTask} exact/>
            <Route path="/tasks/:id/quote" component={ShowTaskQuote} exact/>
            <Route path="/tasks/:id/edit/status" component={EditTaskStatus} exact/>

            <Route path="/general/rates" component={GeneralRates} exact/>

            <Route path="/users/create" component={CreateUser} exact/>
            <Route path="/users/:id" component={ShowUser} exact/>
            <Route path="/users/:id/edit" component={EditUser} exact/>

            <Route path="/customers" component={Customers} exact/>
            <Route path="/customers/create" component={CreateCustomer} exact/>
            <Route path="/customers/:id/edit" component={EditCustomer} exact/>

            <Route path="/languages" component={SourceLanguages} exact/>

            <Route path="/account" component={ShowUserProfile} exact/>

            <Route path="/logs" component={Logs} exact/>

            <PublicRoutes/>
        </Switch>
    );

    const ClientAdminRoutes = rates => (
        <Switch>
            <Route path="/" component={Tasks} exact/>
            <Route path="/tasks" component={Tasks} exact/>
            <Route path="/tasks/cancelled" component={CancelledTasks} exact/>
            <Route path="/tasks/ok/:id/" component={CorrectPayment} exact/>
            <Route path="/tasks/ko/:id" component={IncorrectPayment} exact/>
            <Route path="/tasks/create" component={CreateTaskVerifyFiles} exact/>
            <Route path="/tasks/:id/" component={ShowTask} exact/>
            <Route path="/tasks/:id/quote" component={ShowTaskQuote} exact/>
            <Route path="/tasks/:id/edit" component={CreateTaskVerifyFiles} exact/>

            <Route path="/glossaries" component={Glossaries} exact/>

            {rates && (
                <Route path="/rates" component={Rates} exact/>
            )}

            <Route path="/departments/create" component={CreateDepartment} exact/>
            <Route path="/departments" component={Departments} exact/>
            <Route path="/departments/:id/edit" component={EditDepartment} exact/>
            <Route path="/departments/:id/" component={ShowDepartment} exact/>

            <Route path="/users/create" component={CreateUser} exact/>
            <Route path="/users/:id/edit" component={EditUser} exact/>
            <Route path="/users/:id" component={ShowUser} exact/>
            <Route path="/users" component={Users} exact/>

            <Route path="/account" component={ShowUserProfile} exact/>
            <Route path="/company" component={UserCompany} exact/>

            <PublicRoutes/>
        </Switch>
    );

    const PMRoutes = () => (
        <Switch>
            <Route path="/" component={Tasks} exact/>
            <Route path="/tasks" component={Tasks} exact/>
            <Route path="/tasks/:id/edit/status" component={EditTaskStatus} exact/>
            <Route path="/tasks/:id/" component={ShowTask} exact/>
            <Route path="/tasks/:id/quote" component={ShowTaskQuote} exact/>

            <Route path="/customers" component={Customers} exact/>
            <Route path="/customers/:id/edit" component={EditCustomer} exact/>

            <Route path="/account" component={ShowUserProfile} exact/>

            <PublicRoutes/>
        </Switch>
    );

    const ClientUserRoutes = () => (
        <Switch>
            <Route path="/" component={Tasks} exact/>
            <Route path="/tasks" component={Tasks} exact/>
            <Route path="/tasks/cancelled" component={CancelledTasks} exact/>
            <Route path="/tasks/ok/:id/" component={CorrectPayment} exact/>
            <Route path="/tasks/ko/:id" component={IncorrectPayment} exact/>
            <Route path="/tasks/create" component={CreateTaskVerifyFiles} exact/>
            <Route path="/tasks/:id/edit" component={CreateTaskVerifyFiles} exact/>
            <Route path="/tasks/:id/" component={ShowTask} exact/>
            <Route path="/tasks/:id/quote" component={ShowTaskQuote} exact/>

            <Route path="/account" component={ShowUserProfile} exact/>
            <Route path="/company" component={UserCompany} exact/>

            <PublicRoutes/>
        </Switch>
    );

    const NotUserRoutes = () => (
        <>
            <PublicRoutes/>

            <AuthRoutes />
        </>
    );

    const RoutesByUser = ({ rates }) => {
        switch (userType) {
            case UserTypes.IDISC_ADMIN:
                return IDISCAdminRoutes();
            case UserTypes.IDISC_PM:
                return PMRoutes();
            case UserTypes.CLIENT_ADMIN:
                return ClientAdminRoutes(rates);
            case UserTypes.CLIENT_USER:
                return ClientUserRoutes();
            default:
                return NotUserRoutes();
        }
    }

    const ContentToLoad = () => {
        if (selectedMenu) {
            if (selectedMenu !== "menu_inicio_sesion") {
                if (userType === UserTypes.invitado || location.pathname === "/quote" || location.pathname === "/quote/registered")
                    return (
                        <div onClick={closeDropdown}>
                            <Modal/>
                            <RoutesByUser/>
                        </div>
                    );
                return (
                    <div className="container mt-5 mb-6" onClick={closeDropdown}>
                        <Modal/>
                        <div className="columns">
                            <div className="column is-3 ">
                                <Menu rates={user.customer?.show_rates}/>
                            </div>
                            <div className="column is-9 m-4">
                                <RoutesByUser rates={user.customer?.show_rates}/>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <>
                    <Modal/>
                    <AuthRoutes/>
                </>
            );
        }
        return null
    }

    return (
        <IntlProvider locale={locale.name} messages={locale.object}>
            <ErrorBoundary>
                <Header/>
                <ToastContainer/>
                <ContentToLoad/>
                <Footer/>
            </ErrorBoundary>
        </IntlProvider>
    );
}

export default App;
